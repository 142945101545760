
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import RoleBranchComponent from '@/views/RolesAndPermissions/components/RoleBranchComponent.vue';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { uniqBy } from 'lodash';

@Component({
  components: { RoleBranchComponent }
})
export default class ViewRolePermissionsComponent extends Vue {
  @Prop() public moduleTree!: ModuleTree[];
  private moduleTreeNewRef: ModuleTree[] = [];
  private query = '';
  private querySubject: Subject<string> = new Subject<string>();
  private expandDrawer = false;
  private filteredModules: ModuleTree[] = [];

  private created(): void {
    /**
     * Module tree needs to be filtered when user searches for a module,
     * submodules or individual permissions. As such, a new reference to
     * the module tree is made to avoid mutating the prop value.
     */
    if (this.moduleTree) {
      this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
    }
  }

  private mounted(): void {
    /**
     * Run filter module tree function once every 500ms
     */
    this.querySubject.subscribe((query) => {
      this.filterModuleTree(query);
    });
  }

  private get moduleTreeArray() {
    return this.moduleTreeNewRef;
  }

  private searchModuleTreeEvent(query: string): void {
    this.query = query;
    this.querySubject.next(query);
  }

  /**
   * The function traverses the module tree object and looks for a
   * matching label. The root module is passed each time recursion
   * occurs because the intent is to show the entire scope of where
   * the target module resides.
   * @param query
   * @private
   */
  private filterModuleTree(query: string): void {
    if (!query) {
      this.filteredModules = [];
      this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
    } else {
      this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
      this.filteredModules = [];
      this.moduleTreeNewRef = uniqBy(
        this.searchModuleTree(
          this.moduleTreeNewRef,
          query.toLowerCase().trim()
        ),
        'label'
      );
      this.expandDrawer = true;
    }
  }

  private searchModuleTree(
    moduleTree: ModuleTree[],
    query: string,
    parentModule?: ModuleTree
  ): ModuleTree[] {
    for (const module of moduleTree) {
      const rootModule = module.level === 0 ? module : parentModule;
      if (module.label?.toLowerCase().trim().includes(query)) {
        if (rootModule) {
          this.filteredModules.push(rootModule);
        }
      } else {
        this.searchModuleTree(module.submodules, query, rootModule);
      }
    }

    return this.filteredModules;
  }

  private clearSearch(): void {
    this.query = '';
    this.querySubject.next('');
  }
}
