
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import { Action } from 'vuex-class';
import {
  GetGroupsResponsePayload,
  Group
} from '@/store/modules/admin/types/admin.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { RootState } from '@/store/store';

@Component({
  components: {},
  name: 'RoleBranchComponent'
})
export default class RoleBranchComponent extends Vue {
  @Prop() public module!: ModuleTree;
  @Prop() public isLastModule!: boolean;
  @Prop() public expandDrawer!: boolean;
  @Prop() searchQuery!: string;

  @Action('admin/getSpecificGroups')
  getSpecificGroups!: (groupIds: number[]) => Promise<GetGroupsResponsePayload>;

  private isExpanded = false;
  private isHighlighted = false;
  private selectedSubdivisions = this.module
    ? this.module.subdivisions.filter((subdivision) => subdivision.checked)
    : [];

  private mounted() {
    if (
      this.module.level === 1 ||
      this.expandDrawer ||
      this.searchQuery.length > 0
    ) {
      this.isExpanded = true;
    }

    if (
      this.module &&
      this.module.instanceIds &&
      this.module.instanceIds.length > 0
    ) {
      this.getInstanceData();
    }
  }

  private getInstanceData() {
    switch (this.module.entityType) {
      case EntityTypes.GROUP:
        this.getSpecificGroups(this.module.instanceIds ?? []);
        break;
      default:
        break;
    }
  }

  private get groups(): Group[] | undefined {
    return (this.$store.state as RootState).admin.specificGroups;
  }

  private get instanceLabels(): string[] | undefined {
    switch (this.module.entityType) {
      case EntityTypes.GROUP:
        return this.groups?.map((group) => group.name);
      default:
        return [];
    }
  }

  private get subdivisionLabels(): string {
    return this.selectedSubdivisions.map((item) => item.label).join(', ');
  }

  private get formattedInstances(): string | undefined {
    return this.instanceLabels?.join(', ');
  }

  private showStateIcon(level: number, submodulesLength: number): boolean {
    if (level === 1 && submodulesLength === 0) {
      return true;
    }

    if (level === 1 && submodulesLength > 0) {
      return false;
    }

    return level > 1;
  }

  private showChevron(level: number, submodulesLength: number): boolean {
    if (level === 0 && submodulesLength > 0) {
      return true;
    }

    if (level === 1 && submodulesLength === 0) {
      return false;
    }

    return level > 1 && submodulesLength > 0;
  }

  private scrollToModule() {
    if (
      this.module.label &&
      this.module.label
        .trim()
        .toLowerCase()
        .includes(this.searchQuery.trim().toLowerCase())
    ) {
      const targetModule = document.getElementById(this.module.moduleName);
      if (targetModule) {
        targetModule.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
        this.isExpanded = true;
        this.isHighlighted = true;
      }
    } else {
      this.isHighlighted = false;
    }
  }

  private get moduleLabel(): string | undefined {
    if (!this.searchQuery) {
      return this.module.label;
    }

    if (this.module.label) {
      let label = this.module.label;
      const regexTest = new RegExp(this.searchQuery, 'gi');
      const matchIndex = regexTest.exec(`${this.module.label.toLowerCase()}`);

      if (matchIndex) {
        /**
         * '$&' is a special placeholder for the replace function that,
         * according to MDN, 'inserts the matched substring'.
         *
         * In other words, the function below is replacing the resolve
         * of the regex expression (which is case-insensitive) with a
         * string that is wrapped with a <b> tag.
         */
        label = label.replace(regexTest, '<b>$&</b>');
      }

      return label;
    }

    return this.module.label;
  }

  private isThisTheLastModule(
    index: number,
    submodulesLength: number,
    level: number
  ): boolean {
    if (level === 0) {
      return true;
    }

    if (submodulesLength === 0) {
      return true;
    }

    return index === submodulesLength - 1;
  }

  private toggleDrawer(module: ModuleTree): void {
    // Level 1 modules are always expanded
    if (module.level === 1) {
      this.isExpanded = true;
      return;
    }

    this.isExpanded = !this.isExpanded;
  }

  @Watch('searchQuery')
  private watchSearchQuery() {
    if (this.searchQuery.length > 0) {
      this.scrollToModule();
    } else {
      this.isHighlighted = false;
    }
  }
}
