import { render, staticRenderFns } from "./ViewRolePermissionsComponent.vue?vue&type=template&id=3600d7db&scoped=true&"
import script from "./ViewRolePermissionsComponent.vue?vue&type=script&lang=ts&"
export * from "./ViewRolePermissionsComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ViewRolePermissionsComponent.vue?vue&type=style&index=0&id=3600d7db&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3600d7db",
  null
  
)

export default component.exports