var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"module",class:{
      'level-0-style': _vm.module.level === 0,
      'level-0-padding': _vm.module.level === 0 && _vm.isExpanded,
      'level-1-style': _vm.module.level === 1 && _vm.module.submodules.length > 0,
      'mb-4': _vm.module.level < 2
    }},[_c('div',{class:{
        root: _vm.module.level === 0,
        'mb-4': _vm.module.level === 0 && _vm.isExpanded,
        'module-has-permissions':
          _vm.module.level === 0 && (_vm.module.checked || _vm.module.partiallyChecked)
      }},[_c('div',{class:{
          'level-1-label': _vm.module.level === 1
        }},[_c('div',{class:{
            'root-module-with-chevron': _vm.module.level === 0,
            'level-2-and-above': _vm.module.level > 1,
            'level-2-and-above-bottom-border':
              !_vm.isLastModule && _vm.module.level !== 0,
            'level-1-label-no-modules':
              _vm.module.level === 1 && _vm.module.submodules.length === 0
          },on:{"click":function($event){return _vm.toggleDrawer(_vm.module)}}},[(
              _vm.showStateIcon(_vm.module.level, _vm.module.submodules.length) &&
              _vm.module.checked
            )?_c('i',{staticClass:"mdi mdi-check checked"}):(
              _vm.showStateIcon(_vm.module.level, _vm.module.submodules.length) &&
              _vm.module.partiallyChecked
            )?_c('i',{staticClass:"mdi mdi-minus mixed"}):(
              _vm.showStateIcon(_vm.module.level, _vm.module.submodules.length) &&
              !_vm.module.checked
            )?_c('i',{staticClass:"mdi mdi-close unchecked"}):_vm._e(),_c('div',{staticClass:"module-name-container is-relative"},[_c('p',{class:{ highlighted: _vm.isHighlighted },attrs:{"id":_vm.module.moduleName}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.moduleLabel)}}),(_vm.module.description)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.module.description))])])]),(_vm.selectedSubdivisions.length > 0)?_c('p',{staticClass:"subdivisions"},[_vm._v(" Permissions are applied to "+_vm._s(_vm.module.entityType)+"s in: "),_c('br'),_vm._v(" "+_vm._s(_vm.subdivisionLabels)+" ")]):_vm._e(),(
              Array.isArray(_vm.module.instanceIds) &&
              _vm.module.instanceIds.length > 0
            )?_c('p',{staticClass:"instances"},[_vm._v(" Permissions are applied to the following "+_vm._s(_vm.module.entityType)+"s: "),_c('br'),_vm._v(" "+_vm._s(_vm.formattedInstances)+" ")]):_vm._e(),(_vm.showChevron(_vm.module.level, _vm.module.submodules.length))?_c('div',[(!_vm.isExpanded)?_c('i',{staticClass:"mdi mdi-chevron-down"}):_vm._e(),(_vm.isExpanded)?_c('i',{staticClass:"mdi mdi-chevron-up"}):_vm._e()]):_vm._e()])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.module.submodules.length > 0 && _vm.isExpanded)?_c('div',{staticClass:"submodules"},_vm._l((_vm.module.submodules),function(submodule,index){return _c('div',{key:index},[_c('div',[_c('RoleBranchComponent',{attrs:{"module":submodule,"is-last-module":_vm.isThisTheLastModule(
                  index,
                  _vm.module.submodules.length,
                  _vm.module.level
                ),"search-query":_vm.searchQuery}})],1)])}),0):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }