
import { Vue, Component, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import { Breadcrumb } from '@/components/BreadCrumbs.vue';
import Dropdown, { DropdownOption } from '@/components/base/Dropdown.vue';
import { Action, State } from 'vuex-class';
import { RoleWithTree } from '@/store/modules/role/types/role.types';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import ViewRolePermissionsComponent from '@/views/RolesAndPermissions/components/ViewRolePermissionsComponent.vue';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import RoleModal from '@/views/RolesAndPermissions/components/RoleModal.vue';
import AssignToRoleModal from './components/AssignToRoleModal.vue';
import { Role } from '@/store/modules/role/types/role.types';

@Component({
  components: { ViewRolePermissionsComponent, Container, Dropdown }
})
export default class RolePermissions extends Vue {
  @Action('role/getRoleById')
  private getRoleById!: (id: number) => Promise<RoleWithTree>;

  @Action('role/deleteRole')
  private deleteRoleAction!: (id: number) => void;

  private key = 0;

  @State(({ role }: RootState) => role.apiState.updateRole.success)
  private updateRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.deleteRole.success)
  private deleteRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.getRoleById.success)
  private getRoleByIdSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.getRoleById.error)
  private getRoleByIdErrorState!: boolean;

  @State(({ role }: RootState) => role.role?.resources)
  private roleResources!: string[];

  private mounted(): void {
    // Redirect if no role ID present in route
    if (!this.$route.params.roleId) {
      return this.$router.replace({ name: 'page-not-found' });
    }
    this.getRoleById(+this.$route.params.roleId);
  }

  private get breadcrumbs(): Breadcrumb[] {
    const root: Breadcrumb = {
      route: {
        name: 'roles-and-permissions',
        query: {
          tab: 'Roles'
        }
      },
      text: 'Roles and Permissions'
    };

    const current: Breadcrumb = {
      text: this.roleName ?? 'Role'
    };

    return [root, current];
  }

  private get options(): DropdownOption[] {
    return [
      {
        text: 'Edit role',
        handler: this.openEditRoleModal
      },
      {
        text: 'Assign to role',
        handler: this.assignToRole
      },
      {
        text: 'View assigned',
        handler: this.viewAssigned
      },
      {
        text: 'Delete this role',
        handler: this.deleteRole,
        textColor: 'red'
      }
    ];
  }

  private get role(): RoleWithTree | undefined {
    return (this.$store.state as RootState).role.role;
  }

  private get roleName(): string | undefined {
    return (this.$store.state as RootState).role.role?.name;
  }

  private get roleDescription(): string | undefined {
    return (
      (this.$store.state as RootState).role.role?.description ??
      'No description provided'
    );
  }

  private get usersAssignedString(): string | undefined {
    let userCount = 0;
    const regex = new RegExp(/^\d/);
    if (this.roleResources) {
      for (const user of this.roleResources) {
        if (regex.test(user)) {
          userCount++;
        }
      }
    }

    return `User(s) assigned: ${userCount}`;
  }

  // This is bespoke code for group type instances
  private get groupsAssignedString(): string | undefined {
    let groupCount = 0;
    const regex = new RegExp(/group_\d*/);
    if (this.roleResources) {
      for (const user of this.roleResources) {
        if (regex.test(user)) {
          groupCount++;
        }
      }
    }

    return `Group(s) assigned: ${groupCount}`;
  }

  private get moduleTree(): ModuleTree[] | undefined {
    return (this.$store.state as RootState).role.role?.roleTree ?? [];
  }

  private openEditRoleModal() {
    this.$buefy.modal.open({
      parent: this,
      component: RoleModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        modalTitle: 'Edit Role',
        roleId: +this.$route.params.roleId
      },
      events: {
        refreshRolesList: () => {
          this.getRoleById(+this.$route.params.roleId);
        },
        deleteRoleEvent: () => {
          this.watchDeleteRoleSuccessState();
        }
      }
    });
  }

  private assignToRole(): void {
    this.$buefy.modal.open({
      parent: this,
      component: AssignToRoleModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        role: this.role
      },
      events: {
        updateUsersAssignedCount: () => {
          this.getRoleById(+this.$route.params.roleId);
        },
        updateGroupsAssignedCount: () => {
          this.getRoleById(+this.$route.params.roleId);
        }
      }
    });
  }

  private viewAssigned() {
    this.$router.replace(`/admin/roles/${+this.$route.params.roleId}/assigned`);
  }

  private deleteRole() {
    this.deleteRoleAction(+this.$route.params.roleId);
  }

  @Watch('deleteRoleSuccessState')
  private watchDeleteRoleSuccessState() {
    Toast.open({
      message: 'Role deleted',
      type: 'is-success',
      position: 'is-top'
    });
    this.$router.replace('/admin/roles-and-permissions');
  }

  @Watch('getRoleByIdSuccessState')
  private watchGetRoleByIdSuccessState() {
    this.key++;
  }

  @Watch('getRoleByIdError')
  private watchGetRoleByIdError() {
    if (this.getRoleByIdErrorState) {
      Toast.open({
        message: 'Role not found',
        type: 'is-danger',
        position: 'is-top'
      });
      return this.$router.replace({ name: 'page-not-found' });
    }
  }
}
